<template>
    <div class="fuck">
        <nav class="navbar navbar-expand-lg bg-body-tertiary ">
            <div class="container-fluid container">
                <router-link class="navbar-brand" to="/"><img src="http://www.syueol.com/views/default/images/logo.png"
                        alt=""></router-link>
                <h5 style="color:#1975ff">{{ stuName }}</h5>
            </div>

        </nav>

    </div>


    <div style="padding: 2rem;margin-top: 2rem;" @click="cancelPop($event)">
        <!-- 弹窗 -->
        <div class="alert alert-danger" role="alert" v-show="isShowPop" style="margin: 1rem;">
            {{ popMesg }}
        </div>

        <!-- 列表 -->
        <ul class="list-group">
            <li class="list-group-item" v-for="(item) in list" :key="item.clockId">{{ item.realName }} 日期:{{
                    item.clockTime }}，打卡第{{ item.clockCountDay }}天
            </li>
        </ul>
    </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import { getDesignateClockByPhone } from "../api/clock";

if (!/Mobi|Android|iPhone/i.test(navigator.userAgent)){
      console.log('电脑端');

      while(true){
        alert('请使用手机访问本网站')
      }
    }

const isShowPop = ref(false);
const popMesg = ref('');
const list = ref([]);
const stuName = ref('学生');

//获取该phoneNumber的打卡情况
stuName.value = history.state._realName;
getDesignateClockByPhone(history.state._phoneNumber).then(res => {
    // console.log(res.data);
    if (res.data.msg === 'success') {
        list.value = res.data.data;

        if (res.data.data.length === 0) {
            isShowPop.value = true;
            popMesg.value = '该学生还未打卡'
        } 
    } else {
        isShowPop.value = true;
        popMesg.value = '网络开小差啦'
    }
})


onMounted(() => {
    console.log(history.state._phoneNumber);
})

</script>

<style>

.list-group {
  border-radius: 1rem;
  display: flex;
  /* padding: 2rem; */
  padding-bottom: 3rem;
  max-height: 32rem;
  overflow-y: auto;
}
.list-group-item {
  border-radius: 1rem;
  border: 0.1rem solid #1776ff;
  margin-bottom: 2%;
}
</style>