import axios from "axios";
import router from "@/router";
import { removeToken } from "./tokenFn";

const service = axios.create({
    withCredentials: true,
    // baseURL: 'http://localhost:8089'
    baseURL: 'http://103.149.91.103:8089'
    // timeout: 500000 
})

service.interceptors.request.use(
    config => {
        const localtoken = localStorage.getItem('token')
        if (localtoken) {
            // config.headers['token'] = localtoken;
            config.headers.token = localtoken
        }
        return config
    },
    error => {
        console.log(error)
        return Promise.reject(error)
    })

service.interceptors.response.use(res => {
    if (res.data.code === 0 && res.data.msg === 'NOT_LOGIN') {
        console.log(res);
        // console.log('用户登录');
        removeToken();
        router.replace('/login')
        return res
    } else {
        return res
    }
},

    error => {
        console.log('err' + error)
        let { message } = error;
        if (message == "Network Error") {
            message = "后端接口连接异常";
        }
        else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        }
        else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        return Promise.reject(error)
    })

export default service;