import { createRouter, createWebHashHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import registerView from '../views/registerView'
import indexView from '../views/indexView.vue'
import aboutView from '@/views/aboutView.vue'
import recordView from '@/views/RecordView.vue'
import NotFount404 from '@/views/NotFount404.vue'
import changePasswd from  '@/views/changePasswd.vue'
import adminView from '@/views/adminView.vue'
import noClockStuView from '@/views/noClockStuView.vue'
import stuClockView from '@/views/stuClockView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: indexView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: registerView
  },
  {
    path: '/changePasswd',
    name: 'changePasswd',
    component: changePasswd,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/about',
    name: 'about',
    component: aboutView
  },
  {
    path: '/record',
    name: 'record',
    component: recordView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: adminView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/noClockStu',
    name: 'noClockStu',
    component: noClockStuView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/admin/teacher/stuClock',
    name: 'stuClock',
    component: stuClockView,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/404',
    name: 'notfound',
    component: NotFount404
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    component: NotFount404
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some((r) => r.meta.requireAuth)) {
    const token = localStorage.getItem('token')
    if(token) {
      next()
    } else {
      alert('请先登录')
      next({
        path: '/login',
        query: {redirect: to.fullPath}
      });
    }
  } else {
    next()
  }

  // if(to.name == '/login' && localStorage.getItem('tok')) {
  //   if(localStorage.getItem('token')){
  //     next({
  //       path: 
  //     });
  //   } else {
  //     next()
  //   }
  // }
});

export default router
