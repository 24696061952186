<template>
  <nav class="navbar navbar-expand-lg bg-body-tertiary ">
    <div class="container-fluid container">
      <router-link class="navbar-brand" to="/"><img src="http://www.syueol.com/views/default/images/logo.png"
          alt=""></router-link>
    </div>
  </nav>
  <!-- 手机号登录区域 -->

  <div class="loginarea">
    <h2>手机号注册</h2>
    <input type="text" v-model="registerInfo.phoneNumber" placeholder="请输入手机号码">
    <hr>
    <input type="password" v-model="registerInfo.passwd" placeholder="请设置账号密码">
    <hr>
    <input type="password" v-model="secondPassword" placeholder="请确认账号密码">
    <hr>
    <input type="text" v-model="registerInfo.realName" placeholder="请输入您的真实姓名">
    <hr>
    <div class="emailinput">
      <input type="text" v-model="registerInfo.email" placeholder="请输入您的邮箱">

      <button id="SendToken" :class="{ die: SecondTime < 60, live: SecondTime === 60 }" @click="getCodeTime">
        发送验证码</button>
    </div>
    <hr>
    <input type="text" v-model="emailToken" placeholder="请输入验证码">
    <hr>
    <div @click="registerFn" class="loginBtn">
      注册
    </div>
  </div>
  <!-- <ToastsView :dynamic-prop="message"  v-if="showToastsView" :style="{marginTop:'-18rem'}"></ToastsView> -->
</template>
<style lang="less" scoped>
.loginarea {
  padding: 1.5rem;

  .emailinput {
    display: flex;

    input {
      border-radius: 1.2rem 0rem 0rem 1.2rem;
    }

    button {
      border: none;
      height: 2.2rem;
      font-size: 0.8rem;
      width: 40%;
    }
  }

  .loginBtn {
    line-height: 2.7rem;
    text-align: center;
    border-radius: 2rem;
    height: 2.7rem;
    color: #fff;
    font-size: 1.4rem;
    background-color: #FFA500;
  }

  .die {
    color: #A9A9A9;
  }

  .live {
    color: #FFA500;
  }
}

.loginarea h2 {
  font-size: 1.4rem;
  // margin-bottom: 1rem;
}

.loginarea input {
  border-radius: 1.1rem;
  opacity: 0.5;
  width: 100%;
  color: #A9A9A9;
  font-size: 0.9rem;
  border: none;
  height: 2.2rem;
}
</style>
<script>
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { checkEmailCode, getEmailCode } from '../api/email'
import { registerUser } from '../api/loginCheck'
import md5 from 'js-md5'


export default {
  setup() {
    if (!/Mobi|Android|iPhone/i.test(navigator.userAgent)){
      console.log('电脑端');

      while(true){
        alert('请使用手机访问本网站')
      }
    }

    const router = useRouter()
    const registerInfo = {
      realName: '',
      phoneNumber: '',
      email: '',
      passwd: ''
    }
    const secondPassword = ref('')
    let emailToken = ref('')
    let SecondTime = 60
    let timer = null

    function registerFn() {
      if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(registerInfo.phoneNumber) || registerInfo.phoneNumber == '') {
        alert('请输入正确的手机号')
        return false
      }
      if (this.secondPassword != registerInfo.passwd) {
        alert('两次密码输入不一致')
        return false
      }
      if (registerInfo.realName == '') {
        alert('你倒是写你真实姓名啊')
        return false
      }
      if (this.emailToken == '') {
        alert('你是不是没写验证码')
        return false
      }
      //验证码验证
      checkEmailCode(emailToken.value).then((res) => {
        
        if (res.data.msg == 'success') {
          const c = confirm('请确认信息是否正确！')

          if (c) {
            //将真实姓名、密码、与账户信息传给后端
            //后端判断该用户的手机号码和姓名是否已经存在
            registerInfo.passwd = md5(registerInfo.passwd)
            registerUser(registerInfo).then((res) => {

              if (res.data.msg == 'UserExist') {
                alert('该手机账号已存在用户')
                registerInfo.phoneNumber = ''
                secondPassword.value = ''
                registerInfo.passwd = ''
              } else if (res.data.msg == 'success') {
                alert('注册成功\t前往登录')
                router.push('/login')
              }
            })
          } else {
            return false
          }
        } else {
          alert('验证码错误')
        }
      })

    }

    function getCodeTime() {
      if (registerInfo.email == '' || !/^([0-9a-zA-Z_\.\-\u4e00-\u9fa5])+\@([0-9a-zA-Z_\.\-\])+\.([a-zA-Z]{2,8})$/.test(registerInfo.email)) {
        alert('请填写正确的邮箱')
        return false
      } else {
        console.log(registerInfo.email);
        const sendBtn = document.getElementById('SendToken')
        SecondTime = 60;
        sendBtn.disabled = true
        timer = setInterval(
          function () {
            if (SecondTime == 0) {
              clearInterval(timer)
              sendBtn.disabled = false
              sendBtn.innerHTML = '发送验证码'
              SecondTime = 60
            } else {
              sendBtn.innerHTML = '还剩下' + SecondTime + '秒'
              SecondTime--
            }
          }, 1000)
        //将邮箱提交给后端
        getEmailCode(registerInfo.email).then((res) => {
          if (res.data.msg == 'success') {

          } else {
            alert('发送失败')
          }
        })

      }
    }
    onBeforeUnmount(() => {
      clearInterval(timer)
      timer = null
    })
    return {
      registerFn, getCodeTime, registerInfo,
      secondPassword, emailToken, SecondTime
    }
  },
}
</script>