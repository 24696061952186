<template>
    <div class="fuck" @click="cancelPop($event)">
        <nav class="navbar navbar-expand-lg bg-body-tertiary ">
            <div class="container-fluid container">
                <router-link class="navbar-brand" to="/"><img src="../assets/logo.png"
                        alt=""></router-link>
            </div>
        </nav>
    </div>


    <div class="middle" @click="cancelPop($event)">

        <div class="top" style="display: flex; margin-top: 0.2rem; padding: 0rem 1rem 0 1rem">
            <select class="form-select form-select-lg mb-3" v-model="gradeInput"
                style="display: inline-block;width: 30%;height: 2.5rem;font-size: 1rem; margin-right:1rem "
                aria-label=".form-select-lg example">
                <option selected value="">全部年级</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
            </select>
            <div class="d-flex" role="search" id="nameInput" style="height: 2.5rem">
                <input class="form-control me-2" type="search" placeholder="学生姓名" aria-label="Search"
                    v-model="nameInput">
                <button class="btn btn-outline-success" type="submit" style="width: 35%;font-size: 1rem"
                    @click="searchFn">搜索</button>
            </div>
        </div>

        <div id="dateSelect">
            <h6 style="color:#fff">选择打卡日期</h6>
            <VueDatePicker v-model="date" :format="format" locale="zh-CN"
                :day-names="['一', '二', '三', '四', '五', '六', '七']" @cleared="clearDay">
            </VueDatePicker>
        </div>
        <div id="dateSelect">
            <h6 style="color:#fff">选择打卡(截至)日期</h6>
            <VueDatePicker v-model="dateEnd" :format="formatEnd" locale="zh-CN"
                :day-names="['一', '二', '三', '四', '五', '六', '七']" @cleared="clearDayEnd">
            </VueDatePicker>
        </div>
        <!-- 列表 -->
        <ul class="list-group">
            <li class="list-group-item" v-for="(item) in list" :key="item.clockId">{{ item.realName }} 日期:{{
        item.clockTime }}，打卡第{{ item.clockCountDay }}天
                <button class="checkImage" @click.prevent.stop="showImage(item.imageUrl)">查看图片</button>
            </li>
        </ul>

        <div v-show="showDropdown" id="popDiv">
            <img :src="showImageUrl" style="width: 100%;height: 100%;">
            <!-- <div class="exit" style="background-color: black; width: 3rem; ">dad sad</div> -->
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { getClockListByCondition } from '../api/clock'

if (!/Mobi|Android|iPhone/i.test(navigator.userAgent)){
      console.log('电脑端');

      while(true){
        alert('请使用手机访问本网站')
      }
    }

const date = ref('')
const dateEnd = ref('')
const SearchCondition = {
    realName: '',
    time: '',
    endTime: '',
    grade: ''
}
const clearDayEnd = () => {
    SearchCondition.endTime = ''
}
const clearDay = () => {
    SearchCondition.time = ''
}

const format = data => {
    let day = data.getDate();
    let month = data.getMonth() + 1;
    const year = data.getFullYear();

    if (day < 10) {
        day = `0${day}`
    }
    if (month < 10) {
        month = `0${month}`
    }

    SearchCondition.time = `${year}-${month}-${day}`
    return `你选择的日期是 ${year}-${month}-${day}`
}
const formatEnd = data => {
    let day = data.getDate();
    let month = data.getMonth() + 1;
    const year = data.getFullYear();

    if (day < 10) {
        day = `0${day}`
    }
    if (month < 10) {
        month = `0${month}`
    }

    SearchCondition.endTime = `${year}-${month}-${day}`

    return `你选择的日期是 ${year}-${month}-${day}`
}

const nameInput = ref('')
const gradeInput = ref('')

const list = ref([])
let showDropdown = ref(false)
let showImageUrl = ref('')
getClockListByCondition(SearchCondition).then(res => {
    console.log(res.data.data);
    list.value = res.data.data.sort((a, b) => {
        return a.clockTime - b.clockTime
    })
})

const searchFn = () => {
    SearchCondition.grade = gradeInput.value
    SearchCondition.realName = nameInput.value
    console.log(SearchCondition);
    getClockListByCondition(SearchCondition).then(res => {
        console.log(res.data.data);
        list.value = res.data.data.sort((a, b) => {
            return a.clockTime - b.clockTime
        })
    })
}
const showImage = (url) => {
    const ee = document.getElementsByClassName('checkImage')
    ee.innerHTML = '正在打开图片...'
    showImageUrl.value = url
    showDropdown.value = true
}

const cancelPop = (e) => {
    let tp = document.querySelector('#popDiv')
    if (tp) {
        if (!tp.contains(e.target)) {
            showDropdown.value = false
        }
    }
}
</script>

<style scoped>
html,
body,
#app {
    height: 100%;
    width: 100%;
    margin: 0;
}

.fuck {
    height: 100%;
}

#popDiv {
    /* display: none; */
    border-radius: 1rem;
    /* background-color: crimson; */
    z-index: 11;
    width: 60%;
    height: 60%;
    position: fixed;
    top: 5rem;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    /* position: relative; */
}

#nameInput {
    width: 80%;
    /* margin: 1rem auto; */
}

#popDiv img {
    border-radius: 1rem;
}

.list-group {
    border-radius: 1rem;
    display: flex;
    padding: 2rem;
    padding-bottom: 3rem;
    max-height: 28rem;
    overflow-y: auto;
}

.list-group-item {
    border-radius: 1rem;
    border: 0.1rem solid #1776ff;
    margin-bottom: 2%;
}

#dateSelect {
    width: 80%;
    margin: 0rem auto;
}

.checkImage {
    background-color: #b7d9fe;
    margin-top: 5%;
    text-align: center;
    width: 8rem;
    border: 0.15rem solid #1776ff;
    border-radius: 2rem;
}
</style>