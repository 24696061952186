<template>
    <div class="fuck">
        <nav class="navbar navbar-expand-lg bg-body-tertiary ">
            <div class="container-fluid container">
                <router-link class="navbar-brand" to="/"><img src="http://www.syueol.com/views/default/images/logo.png"
                        alt=""></router-link>
                <h5 style="color:#1975ff">{{ yesterday || moreThanThreeDays ? '先前未打卡学生' : '今日未打卡学生' }}</h5>
            </div>

        </nav>

    </div>

    <div class="middle">

        <div class="top" style="display: flex; margin-top: 1.2rem; padding: 0rem 1rem 0 1rem">
            <select class="form-select form-select-lg mb-3" v-model="SearchHaveNotClockCondition.grade"
                style="display: inline-block;width: 30%;height: 2.5rem;font-size: 1rem; margin-right:1rem "
                aria-label=".form-select-lg example">
                <option selected value=''>全部年级</option>
                <option value="22">22级</option>
                <option value="23">23级</option>
                <option value="24">24级</option>
            </select>
            <div class="d-flex" role="search" id="nameInput" style="height: 2.5rem">
                <input class="form-control me-2" type="search" placeholder="学生姓名" aria-label="Search"
                    v-model="SearchHaveNotClockCondition.realName">
                <button class="btn btn-outline-success" type="submit" style="width: 35%;font-size: 1rem"
                    @click="searchFn">搜索</button>
            </div>
        </div>

        <div class="form-check form-switch" style="width: 12rem;height: 1.8rem;margin: 0.5rem auto;">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault1"
                style="width: 3.3rem;height: 1.8rem;" v-model="yesterday">
            <label
                style="margin-top: 0.2rem;display: inline-block;height: 1.8rem;line-height: 1.8rem;font-size: 1.1rem;font-weight: 700;margin-left: 0.2rem;"
                for="flexSwitchCheckDefault1">昨日未打卡</label>
        </div>
        <div class="form-check form-switch" style="width: 15rem;height: 1.8rem;margin: 0.5rem auto;">
            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault2"
                style="width: 3.3rem;height: 1.8rem;" v-model="moreThanThreeDays">
            <label
                style="margin-top: 0.2rem;display: inline-block;height: 1.8rem;line-height: 1.8rem;font-size: 1.1rem;font-weight: 700;margin-left: 0.2rem;"
                for="flexSwitchCheckDefault2">7日及以上未打卡</label>
        </div>

        <!-- 提示弹窗 -->
        <div class="alert alert-danger" role="alert" v-show="isShowPop" style="margin: 1rem;" @click="dropPop">
            {{ popMesg }} <span style="width: 4rem; display: inline-block;margin-left: 30%;">点我关闭</span>
        </div>

        <!-- 列表 -->
        <ul class="list-group">
            <li class="list-group-item" v-for="(item) in list" :key="item.userId">{{item.grade}} 级 {{ item.realName }}
                <button class="checkStuInfo" @click="toThisStudentClockInfo(item.phoneNumber,item.realName)">看看ta的打卡</button>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { getHaveNotClockList } from '../api/clock'
import { useRouter } from 'vue-router'

if (!/Mobi|Android|iPhone/i.test(navigator.userAgent)){
      console.log('电脑端');

      while(true){
        alert('请使用手机访问本网站')
      }
    }
    
const router = useRouter()
const list = ref([])
const SearchHaveNotClockCondition = {
    realName: '',
    grade: '',
    yesterday: false,
    moreThanThreeDays: false
}

const yesterday = ref(false)
const moreThanThreeDays = ref(false)

const isShowPop = ref(false)
const popMesg = ref('')

const dropPop = () => {
    isShowPop.value = false
}

// 今日未打卡名单


getHaveNotClockList(SearchHaveNotClockCondition).then(res => {
    if (res.data.msg === 'success') {
        list.value = res.data.data
    } else {
        alert('网络开小差啦，请重试')
    }
    console.log(SearchHaveNotClockCondition);
    console.log(list.value);
})

const searchFn =  () => {
    if (yesterday.value === true && moreThanThreeDays.value === true) {
        isShowPop.value = true
        popMesg.value = '不要两个都选哦~'
        yesterday.value = false
        moreThanThreeDays.value = false

        return false
    }
    SearchHaveNotClockCondition.yesterday = yesterday.value
    SearchHaveNotClockCondition.moreThanThreeDays = moreThanThreeDays.value

    getHaveNotClockList(SearchHaveNotClockCondition).then( res => {
        if (res.data.msg === 'success') {
            list.value = res.data.data
        } else {
            alert('网络开小差啦，请重试')
        }
    })
    // console.log(SearchHaveNotClockCondition);
}

const toThisStudentClockInfo = (phoneNumber, realName ) => {
    router.push({
        path: '/admin/teacher/stuClock',
        state: {
            _phoneNumber: phoneNumber,
            _realName: realName
        }
    })

}



</script>

<style scoped>
html,
body,
#app {
    height: 100%;
    width: 100%;
    margin: 0;
}

.fuck {
    height: 100%;
}

#popDiv {
    /* display: none; */
    border-radius: 1rem;
    /* background-color: crimson; */
    z-index: 11;
    width: 60%;
    height: 60%;
    position: fixed;
    top: 5rem;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    /* position: relative; */
}

#nameInput {
    width: 80%;
    /* margin: 1rem auto; */
}

#popDiv img {
    border-radius: 1rem;
}

.list-group {
    border-radius: 1rem;
    display: flex;
    padding: 2rem;
    padding-bottom: 3rem;
    max-height: 32rem;
    overflow-y: auto;
}

.list-group-item {
    border-radius: 1rem;
    border: 0.1rem solid #1776ff;
    margin-bottom: 2%;
}

.checkStuInfo {
    float: right;
    background-color: #b7d9fe;
    margin-top: 5%;
    text-align: center;
    width: 50%;
    border: 0.15rem solid #1776ff;
    border-radius: 2rem;
}
</style>
