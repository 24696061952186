<template>
  <div class="fuck">
    <nav class="navbar navbar-expand-lg bg-body-tertiary ">
      <div class="container-fluid container">
        <router-link class="navbar-brand" to="/"><img src="../assets/logo.png"
            alt=""></router-link>
      </div>
    </nav>
    <!-- 手机号登录区域 -->
    <div class="loginarea">
      <h2>手机号登录</h2>
      <input type="text" v-model="userInfo.phoneNumber" placeholder="请输入手机号码">
      <hr>
      <input type="password" v-model="userInfo.passwd" placeholder="请输入账号密码">
      <hr>
      <input type="text" v-model="teacher_name" placeholder="请输入你的老师名~">
      <hr>
      <select class="form-select form-select-lg mb-3" v-model="grade"
        style="display: inline-block;width: 100%;height: 2.5rem;font-size: 1rem; "
        aria-label=".form-select-lg example">
        <option selected value="">选择你的年级</option>
        <option value="22">22</option>
        <option value="23">23</option>
        <option value="24">24</option>
      </select>
      <hr>

      <!-- 弹窗 -->
      <div class="alert alert-danger" role="alert" v-show="isShowPop" style="margin: 1rem;">
        {{ popMesg }}
      </div>

      <div @click="loginBtnFn" class="loginBtn">
        登录
      </div>
      <router-link class="toRegister" to="/register">没有账号？点此注册</router-link>
    </div>
  </div>
</template>
<style lang="less" scoped>
.loginarea {
  padding: 1.2rem 2rem;

  .toRegister {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loginBtn {
    line-height: 2.5rem;
    text-align: center;
    margin-top: 1rem;
    border-radius: 2rem;
    height: 2.5rem;
    color: #fff;
    font-size: 1.2rem;
    background-color: #FFA500;
  }

  .die {
    color: #A9A9A9;
  }

  .live {
    color: #FFA500;
  }
}

.loginarea h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.loginarea input {
  margin-top: 0.2rem;
  border-radius: 1.2rem;
  opacity: 0.7;
  width: 100%;
  color: #A9A9A9;
  font-size: 1rem;
  border: none;
  height: 2.7rem;
}
</style>
<script setup>
import { onMounted, ref } from 'vue'
import md5 from 'js-md5'
import { setTeacher, loginFn, setGrade } from '../api/loginCheck'
import { useRouter } from 'vue-router'

if (!/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
  console.log('电脑端');

  while (true) {
    alert('请使用手机访问本网站')
  }
}

const router = useRouter()
const userInfo = {
  phoneNumber: '',
  passwd: ''
}

let token = ''

// 老师名
const teacher_name = ref('')
// 年级
const grade = ref('')


const isShowPop = ref(false)
const popMesg = ref('')

let lastPop = false

const loginBtnFn = () => {
  if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(userInfo.phoneNumber) || userInfo.phoneNumber == '') {
    isShowPop.value = true
    popMesg.value = '请输入正确的手机号'
    return false
  }
  if (userInfo.passwd == '') {
    isShowPop.value = true
    popMesg.value = '请输入密码'
    return false
  }
  if (teacher_name.value == '') {
    const c = confirm('好好好，你是老师？')
    if (!c) {
      isShowPop.value = true
      popMesg.value = '请输入老师名'
      return false
    }
  }
  if (grade.value == '') {
    lastPop = confirm('冒充老师后果自负哦')
    if (!lastPop) {
      isShowPop.value = true
      popMesg.value = '请输入年级'
      return false
    }
  }
  //登录
  //向后端接口post手机号与密码
  //后端返回一个token
  //给passwd加密
  const teacher_stuPhone = {
    teacherName: teacher_name.value,
    stuPhoneNumber: userInfo.phoneNumber
  }

  const stuPhone_grade = {
    grade: grade.value,
    stuPhoneNumber: userInfo.phoneNumber
  }

  if (!lastPop) {
    setGrade(stuPhone_grade.stuPhoneNumber, stuPhone_grade.grade).then(res => {
      if (res.data.msg != 'success') {
        isShowPop.value = true
        popMesg.value = '年级设置失败'
        return false
      }
    })
    setTeacher(teacher_stuPhone.teacherName, teacher_stuPhone.stuPhoneNumber).then((res) => {
      console.log(res.data)
      if (res.data.msg != 'success') {
        isShowPop.value = true
        popMesg.value = '老师名设置失败'
        return false
      }
    })
  } 

  
  userInfo.passwd = md5(userInfo.passwd)

  loginFn(userInfo).then((res) => {
    if (res.data.msg === '用户名或密码错误') {
      alert('用户名或密码错误')
    } else if (res.data.msg == 'success') {
      token = res.data.data
      localStorage.setItem('token', token)
      alert('登录成功！')
      router.push('/')
    }
  })
}

onMounted(() => {

})

</script>
