import request from '@/utils/request'

export function getEmailCode(data) {
  return request({
    url: '/GetEmail?email='+data,
    method: 'get'
  })
}

export function checkEmailCode(data) {
  return request({
    url: '/CheckEmail?code='+data,
    method: 'post'
  })
}
