<template>
  <div class="fuck">
    <nav class="navbar navbar-expand-lg bg-body-tertiary ">
      <div class="container-fluid container">
        <router-link class="navbar-brand" to="/"><img src="http://www.syueol.com/views/default/images/logo.png"
            alt=""></router-link>
      </div>
    </nav>
  </div>

  <div class="inputForm">
    <h1>更改密码</h1>
    <div class="form-floating mb-3" style="margin-top: 3rem;">
      <input type="email" class="form-control" v-model="userInfo.email" style="border-radius: 1.5rem; height: 3.5rem;" id="floatingInput"
        placeholder="name@example.com">
      <label for="floatingInput">输入邮箱</label>
    </div>
    <div class="form-floating" style="display: flex; margin-top: 2rem;">
      <input type="numberNew" class="form-control" v-model="emailToken" id="floatingPassword" style="border-radius: 1.5rem 0 0 1.5rem;
    
      height: 3.5rem;">
      <label for="floatingPassword">
        验证码
      </label>
      <button id="SendToken" style="height: 3.4rem; border: 0" :class="{die: SecondTime <60, live: SecondTime === 60}" @click="getCodeTime">发送验证码</button>
    </div>

    <div class="form-floating">
      <input type="password" class="form-control" style="border-radius: 1.5rem;margin-top: 2rem;" id="floatingPassword"
      v-model="userInfo.passwd"
        placeholder="Password">
      <label for="floatingPassword">更改的密码</label>
    </div>

    <div class="form-floating">
      <input type="password" class="form-control" style="border-radius: 1.5rem;margin-top: 2rem;" id="floatingPassword"
      v-model="secondPassword"
        placeholder="Password">
      <label for="floatingPassword">确认密码</label>
    </div>

    <div class="alert alert-danger" role="alert" v-show="isShowPop" style="margin: 1rem;">
      {{ popMesg }}
    </div>

    <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
      <symbol id="check-circle-fill" viewBox="0 0 16 16">
        <path
          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
      </symbol>
      <symbol id="info-fill" viewBox="0 0 16 16">
        <path
          d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
      </symbol>
      <symbol id="exclamation-triangle-fill" viewBox="0 0 16 16">
        <path
          d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
      </symbol>
    </svg>


    <hr>
    <div @click="loginFn" class="loginBtn">
      登录
    </div>

  </div>
</template>
<style scoped>
.inputForm {
  padding: 2rem;
  opacity: 0.8;
}

.alert-danger {
  opacity: 0.8;
}

#SendToken {
  height: 3.5rem;
  line-height: 3.5rem;
  text-align: center;
  font-size: 1rem;
  color: #fff;
  width: 45%;
  border-radius: 0 1.5rem 1.5rem 0;
  background-color: #FFA500;
}

.loginBtn {
  line-height: 2.5rem;
  text-align: center;
  margin-top: 1.2rem;
  border-radius: 2rem;
  height: 2.5rem;
  color: #fff;
  font-size: 1.2rem;
  background-color: #FFA500;
}

.die {
  color: #A9A9A9;
}

.live {
  color: #FFA500;
}
</style>

<script setup>
import { ref, onBeforeUnmount } from 'vue'
import { sendEmail, checkEmail, changePasswd } from '../api/loginCheck'
import { useRouter } from 'vue-router'
import { getEmailCode, checkEmailCode } from '../api/email'
import {changePassword} from '../api/loginCheck'
import md5 from 'js-md5'

if (!/Mobi|Android|iPhone/i.test(navigator.userAgent)){
      console.log('电脑端');

      while(true){
        alert('请使用手机访问本网站')
      }
    }
const isShowPop = ref(false)
const router = useRouter()
const popMesg = ref('')
const SecondTime = ref(60)
const userInfo = {
  email: '',
  passwd: ''
}
const emailToken = ref('')
const secondPassword = ref('')
let timer = null

//发送验证码
const getCodeTime = () => {
  if (userInfo.email == '') {
    isShowPop.value = true
    popMesg.value = '请输入邮箱'
    return
  }
  isShowPop.value = false
  //发送验证码
  const sendButton = document.getElementById('SendToken')
  SecondTime.value = 60;
  sendButton.disabled = true
  const timer = setInterval(() => {
    if (SecondTime.value === 0) {
      sendButton.disabled = false
      sendButton.innerHTML = '发送验证码'
      clearInterval(timer)
      SecondTime.value = 60
    } else {
      sendButton.innerHTML = '还剩下'+SecondTime.value + 's'
      SecondTime.value--
    }
  }, 1000)

  //邮箱提交给后端
  getEmailCode(userInfo.email).then(res => {
    if(res.data.msg == 'success') {     
      } else {
         popMesg.value = '发送失败'
         isShowPop.value = true
      }
  })

}


const loginFn = () => {
  //检查验证码
  if (userInfo.email === '' || userInfo.passwd === '') {
    isShowPop.value = true
    popMesg.value = '请输入完整信息'
    return
  }
  //检查两次密码输入是否相同
  if (userInfo.passwd !== secondPassword.value) {
    isShowPop.value = true
    popMesg.value = '两次密码输入不一致'
    return
  }

  if(emailToken.value == '') {
    isShowPop.value = true
    popMesg.value = '请输入验证码'
    return
  }

  userInfo.passwd = md5(userInfo.passwd)
  //验证码验证
  checkEmailCode(emailToken.value).then(res => {
    if(res.data.msg == 'success') {

      changePassword(userInfo).then(res => {
        if(res.data.msg == 'success') {
          alert('修改成功！')
          router.push('/login')
        } else {
          isShowPop.value = true
          popMesg.value = '修改失败'
        }
      })

    } else {
      isShowPop.value = true
      popMesg.value = '验证码错误'
      return
    }
  }
)



  // router.push('/login')
}
onBeforeUnmount(() => {
      clearInterval(timer)
      timer = null
    })

</script>