import request from '@/utils/request'

export function checkToken() {
    return request({
        url: '/checkLogin',
        method: 'post'
    })
}

//更改用户密码
export function changePassword(data) {
    return request({
        url: '/changePasswd',
        method: 'post',
        data
    })
}

export function setTeacher(teacherName, stuPhoneNumber) {
    return request({
        url: '/login/setTeacher?teacherName='+teacherName+'&'+'stuPhoneNumber='+stuPhoneNumber,
        method: 'post'
    })
}

// 设置年级
export function setGrade(stuPhoneNumber,grade) {
    return request({
        url: '/login/setGrade?stuPhoneNumber='+stuPhoneNumber+'&'+'grade='+grade,
        method: 'post'
    })
}

export function registerUser(data) {
    return request({
        url: '/register',
        method: 'post',
        data
    })
}

export function loginFn(data) {
    return request({
        url: '/login',
        method: 'post',
        data
    })
}