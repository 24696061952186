import request from '@/utils/request'

export function uploadImg(formData) {
    return request({
        url: '/upload',
        method: 'post',
        data: formData,
        headers: {
            "Content-Type":'form-data',
            "Token": localStorage.getItem('token')
        }
    })
}

export function insertClock(data){
    return request({
        url: '/clock',
        method: 'post',
        data: data
    })
}