import request from '@/utils/request'

export function getClockList() {
  return request({
    url: '/clock/list',
    method: 'get'
  })
}

// 查询打卡的学生名单
export function getClockListByCondition(data) {
  return request({
    url: '/admin/getByCondition',
    method: 'post',
    data
  })
}


// 今日未打卡学生list （不同老师查的结果不同）
export function getHaveNotClockList(data) {
  return request({
    url: '/admin/todayHaveNotClockList',
    method: 'post',
    data
  })
}

//根据phoneNumber 来获取学生打卡信息
export function getDesignateClockByPhone(data) {
  return request({
    url: '/admin/clock/getByPhone?phoneNumber='+data,
    method: 'post'
  })
}
