<template>
  <div class="fuck">
    <!-- 导航页 -->
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
      <div class="container-fluid container">
        <router-link class="navbar-brand" to="/"
          ><img
            src="../assets/logo.png"
            alt=""
        /></router-link>
        <router-link class="nav-link" to="/myHome">
          Hi!{{ Username }}
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link class="nav-link active" to="/"
                >主页</router-link
              >
            </li>
            <li class="nav-item" v-show="showLogin.value">
              <router-link to="/login" class="nav-link">登录</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/record" v-show="!isTeacher" class="nav-link">我的打卡记录</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/noClockStu" v-show="isTeacher" class="nav-link active">未打卡学生记录</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/changePasswd" class="nav-link">修改密码</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/about"
                >打卡使用说明</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/login"  @click="exitLogin">退出登录</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- 轮播图 -->
    <div
      id="carouselExampleRide"
      class="carousel slide container"
      data-bs-ride="true"
    >
      <div class="carousel-inner">
        <router-link to="/about">
        <div class="carousel-item active">
          <img src="@/assets/img/lb1.jpg" class="d-block w-100" alt="..." />
        </div>
      </router-link>
      <router-link to="/about">
        <div class="carousel-item">
          <img src="@/assets/img/lb1.jpg" class="d-block w-100" alt="..." />
        </div>
      </router-link>
      <router-link to="/about">
        <div class="carousel-item">
          <img src="@/assets/img/lb1.jpg" class="d-block w-100" alt="..." />
        </div>
      </router-link>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleRide"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleRide"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <!-- 打卡区域 -->
    <button class="calendar">
      <i class="bi-calendar-event"></i>
      <div class="text">{{ isTeacher ? '老师好！': '签到' }}</div>
      <div class="time">
        <span class="month">{{ month }}/{{ day }}</span
        ><span class="week">{{ week }}</span>
      </div>
    </button>
  <!-- 图片上传区域 -->
    <div class="input-group" v-if="!isTeacher" style="padding: 1.5rem;">
       <input type="file" class="form-control" id="fileInput" accept="image/*" aria-describedby="inputGroupFileAddon04" aria-label="Upload">
       <button class="btn btn-outline-secondary" type="button" id="uploadButton" @click="uploadNewImg()">上传</button>
    </div>

    <!-- 教师端查看学生打卡情况 -->

    <div class="alert alert-dark" role="alert" v-if="isTeacher" style="margin: 2rem 2rem; height: 5rem; font-size: 1.2rem;text-align: center;line-height: 3rem; border-radius:2rem">
      <router-link to="/admin" style="text-decoration: none;color: white">来看看同学们的打卡情况吧！</router-link>
    </div>

    <div class="footer">
      <div class="footer-text fixed-bottom">Copyright © 2024. yxy All rights reserved.</div>
    </div>


  </div>
</template>

<style lang="less">
*,
::after,
::before {
  box-sizing: border-box;
}
body,
ul,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, PingFangSC-Regular,
    "PingFang SC", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  color: #333;
  overflow: hidden;
}
img {
  vertical-align: bottom;
}
ul {
  list-style-type: none;
}
a {
  color: #333;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html {
  height: 100%;
}
body {
  height: 100%;
  width: 100%;
  background-image: url(../assets/img/desktop_4.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  backdrop-filter: blur(3px);
}
.fuck {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.imageup {
  width: 13rem ;
  height: 3.5rem ;
  display: block !important;
  font-size: 1.5rem !important;
  border-radius: 1rem !important;
  border: #a1c4fd 0.1rem solid !important;
  margin: 3rem auto !important;
}
.navbar {
  border-radius: 0 0 1rem 1rem;
  box-shadow: 0 0 1rem #808080;
}
.carousel {
  height: 13rem;
  margin-top: 1rem;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #808080;
  border-radius: 50%;
  background-size: 1.5rem;
}
.carousel-item img {
  border-radius: 1rem;
}
.toast {
  margin: -5rem auto;
  border-radius: 1rem;
  width: 15rem;
}
.toast .toast-body {
  text-align: center;
  font-size: 1rem;
}
.toast-header {
  border-radius: 1rem;
}
.toast-header img {
  width: 5rem;
  height: 2rem;
  object-fit: contain;
}
.calendar {
  border: 0;
  border-radius: 2rem;
  width: 8rem;
  height: 8rem;
  margin: 3rem auto;
  background-color: rgba(255, 255, 255, 0.6);
  padding-top: 1.5rem;
  display: block;
  align-items: center; //可有可无
}
.calendar .bi-calendar-event {
  font-size: 1.4rem;
  color: #000;
}
.calendar .bi-calendar-check {
  font-size: 1.4rem;
  color: #000;
}
.calendar .text {
  font-weight: 700;
  color: #000;
  margin-top: 0.4rem;
  font-size: 1rem;
}
.calendar .time {
  font-weight: 700;
  color: #000;
  margin-top: 0.3rem;
  font-size: 0.7rem;
}
.viewStudentClock{
  font-size: 1.5rem;
}
.footer-text{
    font-size: 1rem;
    color: #fff;
    text-align: center;
    margin-bottom: 2rem
  }
</style>
<script>
import {checkToken} from '@/api/loginCheck'
import { uploadImg } from '@/api/uploadImage'
import { onMounted, ref, toRefs } from "vue";
import { removeToken } from '@/utils/tokenFn'
import { insertClock } from '@/api/uploadImage';
export default {
  setup() {

    if (!/Mobi|Android|iPhone/i.test(navigator.userAgent)){
      console.log('电脑端');

      while(true){
        alert('请使用手机访问本网站')
      }
    }

    let isTeacher = ref(false)
    let Username = ref('用户')
    let showLogin = ref(true)
    const weekarr = [
      " Mon.",
      " Tue.",
      " Wed.",
      " Thurs.",
      " Fri.",
      " Sat.",
      " Sun.",
    ];
    const now_time = new Date();
    const day = now_time.getDate();
    const month = now_time.getMonth() + 1;
    const week = weekarr[now_time.getDay() - 1];
    let imageUrl = ref('');
    let ClockData = {
      imageUrl: '',
    }



    function calenderFn() {
      if (document.querySelector(".bi-calendar-event") != null) {
        const iconfont = document.querySelector(".bi-calendar-event");
        iconfont.className = "bi-calendar-check";
        const text = document.querySelector(".calendar .text");
        text.innerHTML = "签到成功";
        const calenderDay = String(month)+"-"+String(day)
        localStorage.setItem('calender',calenderDay)
      }
    }
    function checkCalenderStatus() {
      // 获取后端数据判断当天是否已经签到
      //获取数据
      // if(获取的数据=='true'){
      //   calenderFn()
      // }
      if(localStorage.getItem('calender') == String(month)+"-"+String(day)){
        const button = document.getElementById("uploadButton")
        button.disabled=true
        button.innerHTML='已上传'
        calenderFn()

      }
    }

    function checkLoginStatus() {
      const token = localStorage.getItem('token')
      if(token){
        showLogin = false
        checkToken().then((res) => {
          Username.value = res.data.data.name
          isTeacher.value = res.data.data.isAdmin
          // console.log(res.data);
        })


      }
      
    }

    // 上传新图片
    async function uploadNewImg() {

      // 构造表单数据
      const formData = new FormData();
      // --- 文件
      const fileinput = document.getElementById("fileInput")
      // console.log(fileinput);
      const file = fileinput.files[0];
      const button = document.getElementById("uploadButton")
      formData.append("image", file);
      // 文件不能为空
      if (file) {
        // axios.post("http://localshot:8089/upload",formData,{
        //   headers:{
        //     "Content-Type":'form-data',
        //     "Token": localStorage.getItem('token')
        //   }
        // }
        // )
        button.innerHTML='上传中'
      button.disabled = true;
        await  uploadImg(formData)
        .then(res => {
          if (res.data.msg == "success") {
            
            button.disabled = false;
            button.innerHTML='上传完成'
            button.value= ""
            ClockData.imageUrl = res.data.data
            console.log(ClockData.imageUrl);

            

            insertClock(ClockData).then(res => {
              if (res.data.msg == "success") {
                calenderFn()
                checkCalenderStatus()
              } else {
                alert('打卡失败')
              }
            })

            // imageUrl.value = res.data.data
            alert('上传成功')
            } else if (res.data.code == 0) {
              alert('上传失败')
            }
        })
      } else {
        // --- 给提示框赋值
       alert("放图片啊")
      }
    }

    // 退出登录
    function exitLogin() {
      removeToken()
    }

    onMounted(() => {
      checkLoginStatus();
      checkCalenderStatus();
    });
    return {
      day,
      month,
      week,
      imageUrl,
      showLogin,
      Username,
      isTeacher,
      calenderFn,
      checkCalenderStatus,uploadNewImg,
      checkLoginStatus,
      exitLogin
    };
  },
};
</script>