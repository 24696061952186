<template>
  <div class="fuck" @click="cancelPop($event)">
    <!-- 导航页 -->
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
      <div class="container-fluid container">
        <router-link class="navbar-brand" to="/"
          ><img
            src="../assets/logo.png"
            alt=""
        /></router-link>
      </div>
    </nav>

    <!-- 区域 -->

    <h3 class="topictext">查看历史打卡数据</h3>
    <!-- 列表 -->
     <ul class="list-group">
      <li class="list-group-item" v-for="(item) in list" :key="item.clockId">日期：{{ item.clockTime.replace('T',' ') }}，打卡第{{ item.clockCountDay }}天
        <button class="checkImage" @click.prevent.stop="showImage(item.imageUrl)">查看图片</button>       
      </li> 
    </ul>

    <div v-show="showDropdown" id="popDiv">
      <img :src="showImageUrl" style="width: 100%;height: 100%;">
      <!-- <div class="exit" style="background-color: black; width: 3rem; ">dad sad</div> -->
    </div>
  </div>
</template>

<script>
import {getClockList} from '../api/clock'
import {ref} from 'vue'
export default {
  setup(){
    if (!/Mobi|Android|iPhone/i.test(navigator.userAgent)){
      console.log('电脑端');

      while(true){
        alert('请使用手机访问本网站')
      }
    }

    let showDropdown = ref(false)
    let showImageUrl =ref('')
    const list = ref([])
    getClockList().then(res=>{
      list.value = res.data.data.sort((a,b)=>{
        return  b.clockCountDay - a.clockCountDay
      })
      console.log(res.data.data);
    })

    const showImage = (url)=>{
      const ee = document.getElementsByClassName('checkImage')
      ee.innerHTML = '正在打开图片...'
      showImageUrl.value = url
      // document.getElementById('popDiv').style.display = 'block'
      showDropdown.value = true
    }

    const cancelPop = (e)=>{
      let tp = document.querySelector('#popDiv')
      if(tp) {
        if (!tp.contains(e.target)) {
          showDropdown.value = false
        }
      }
    }
    
    return{
      list,showImageUrl,showImage,showDropdown,cancelPop
    }
  }
}
</script>

<style scoped>
html,
body,
#app {
  height: 100%;
  width: 100%;
  margin: 0;
}
.fuck {
  height: 100%;
}
#popDiv{
            /* display: none; */
            border-radius: 1rem;
            /* background-color: crimson; */
            z-index: 11;
            width: 60%;
            height: 60%;
            position:fixed;
            top:5rem;
            right:0;
            left:0;
            bottom:0;
            margin:auto;
            /* position: relative; */
}
#popDiv img{
  border-radius: 1rem;
}

.list-group {
  border-radius: 1rem;
  display: flex;
  padding: 2rem;
  padding-bottom: 3rem;
  max-height: 32rem;
  overflow-y: auto;
}
.list-group-item {
  border-radius: 1rem;
  border: 0.1rem solid #1776ff;
  margin-bottom: 2%;
}
.topictext {
  font-size: 1.2rem;
  margin-top: 2rem;
  text-align: center;
}
.checkImage{
  background-color: #b7d9fe;
  margin-top: 5%;
  text-align: center;
  width:8rem;
  border: 0.15rem solid #1776ff;
  border-radius: 2rem;
}
</style>