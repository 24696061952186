<template>
  <nav class="navbar navbar-expand-lg bg-body-tertiary ">
        <div class="container-fluid container">
        <router-link class="navbar-brand" to="/"><img src="../assets/logo.png"
            alt=""></router-link>
        </div>
   </nav>
    <div class="accordion accordion-flush" id="accordionFlushExample" style="padding: 2rem;margin-top: 0.6rem;">
  <div class="accordion-item" >
    <h2 class="accordion-header" id="flush-headingOne">
      <button class="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
        关于该网站
      </button>
    </h2>
    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body"><h4>© 2024 YXY All rights reserved.</h4>
        <p class="useinfo"><code style="font-size: 1.2rem;">该网站的使用方法：</code><br>注册登录后，在主页上传每日单词打卡的截图即可。</p>
        <p class="myself">本人主要方向为大数据与机器学习，前端只为副业，所以网站如果有bug请各位多多向我提出，
        我会努力修改的，在此感谢司越教育。</p>
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
        关于司越
      </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">司越教育是一家立足浙江，辐射全国的综合性教育服务集团。司越的业务覆盖统招专升本考试培训，考公、考研培训，提前招生培训，海外留学服务及职业技能专项培训。在浙江省教育培训行业中独树一帜。浙江省内，司越40余家分校遍布全省各地，浙江省外，司越分部驻足江西、安徽等多个省份。时至今日，司越教育的版图仍在不断扩大。</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
        想上本科？
      </button>
    </h2>
    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">你都点进来了，那你一定是一个好学上进的同学，正如你所看到的：<code style="font-size: 1.2rem;">想上本科，就来司越！</code>扫描下方二维码，添加老师微信，踏上你的逐梦之路</div>
    </div>
  </div>
</div>
   
    
    <div class="card" style="margin: 0rem 2rem 0 2rem;height: 25rem;">
  <img src="../assets/img/mwc.jpg" style="width: 100%;height: 100%;object-fit: cover;" class="card-img-top">

</div>

    <div class="footer">
      <div class="footer-text">Copyright © 2024. All rights reserved.</div>
    </div>
</template>

<script setup>
    if (!/Mobi|Android|iPhone/i.test(navigator.userAgent)){
      console.log('电脑端');

      while(true){
        alert('请使用手机访问本网站')
      }
    }

</script>

<style lang="less" scoped>
  .text{
    h4{
      text-align: center;
      margin-top: 2.3rem;
    }
    .useinfo{
      padding: 1rem;
      font-size: 1rem;
    }
    .myself{
      padding: 2rem;
      margin-top: -4rem;
      font-size: 1rem;
    }
  }
  .footer-text{
    font-size: 1rem;
    color: #fff;
    text-align: center;
  }
</style>